import React, {useEffect,useState} from 'react'
import modalstyles from "./ExitIntentModal.module.css"
// import NewsletterExpanded2 from '@widgets/NewsletterExpanded2'
import NewsletterForm from '@components/NewsletterForm'
import useSGAutorepondeur from '@helpers/useSGAutorepondeur'
import Modal from 'react-modal';
import { Main } from '@layout'
import { Card, Text, Heading, Box, Flex } from 'theme-ui'
import { FaCheck, FaTimesCircle } from 'react-icons/fa'
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const ExitIntentModal = props => {

  const styles = {
    link:{
      textDecoration: `none`,
      color:`black`,
      textAlign: `right`
    },
    card: {
      position: `relative`,
      zIndex:2,
      p:0,
      borderRadius:0
    },
    box1: {
      display: [`none`, `none`, `block`],
      size:'1/2',
      p:4,
      textAlign: `center`
    },
    box2: {
      size: [`full`, `full`, `1/2`],
      p:4,
      textAlign: `center`
    },
    header : {
      px:4,
      py:3,
      textAlign: `center`,
      color: `white`
    },
    horizontal: {
      display: `flex`,
      justifyContent: [`flex-start`, `space-evenly`],
      flexWrap: `nowrap`,
      overflowX: `auto`,
      width: `auto`,
    },
    h1: {
      fontSize: `2em`,
      color:`white`
    },
    h2: {
      fontSize: `1.2m`,
      color:`white`
    },
    h2Black: {
      fontSize: `1.6em`,
      color:`black`
    },
    h3: {
      fontSize: `1.1em`,
      color:`white`
    },
    pBlack: {
      fontSize: `1em`,
      color:`black`
    },
    icons: {
      display: [`none`, `none`, `block`],
      position: `absolute`,
      top: `-3rem`,
      left: `5rem`,
      svg: {
        display: `block`
      }
    },
    plane: {
      fontSize: `9rem`,
      color: `beta`
    },
    wind: {
      fontSize: `7rem`,
      color: `omegaLight`,
      transform: `rotate(120deg)`,
      mt: `0.5rem`,
      ml: `-3rem`
    },
    form: {
      // mx: `auto`,
      mt: 0
    }
  }

  // use show to determine if you should display the modal or not 
  const [show, setShow] = useState(props.show)

  const {
    handleSubmit,
    canSubmit,
    submitting,
    message,
    success,
  } = useSGAutorepondeur()
  
  //Render Rich Text from Cotentful
  const options = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
        [BLOCKS.UL_LIST]: (node, children) => {
          return <ol style={{listStyle:`none`, padding:0, fontSize:`1.1em`}}><li><FaCheck style={{color:`green`}}/> {children[0].props.children[0].props.children}</li></ol>
        },
        [BLOCKS.EMBEDDED_ASSET]: node => {
            const {
              fluid: { src },
              title,
            } = node.data.target
            
          return <img src={src} alt={title} />
        },

      }
  };

  useEffect(() => {
    if (props.show) {
      let expiryDate = new Date(
        Date.now() + 7 * (1000 * 60 * 60 * 24)
      )
      expiryDate.setFullYear(expiryDate.getFullYear() + 1)
      document.cookie = "modal_exit_seen" + "=true; expires=" + expiryDate.toUTCString()
    }
  	
    setShow(props.show)
  }, [props.show])

  // you can create a function to close the modal after the user clicks the "x" button or subscribes
  const closeModal = () => {
    setShow(false)
  }

  return show && (
    <Modal
      isOpen={show}
      onRequestClose={closeModal}
      className={modalstyles.modal}
      overlayClassName={modalstyles.overlay}
      contentLabel="Example Modal"
    >   
      <Main>
        <Card variant='paper' sx={styles.card}>
            {/**<Flex>
              <Box p={1} bg='primary' sx={styles.box1}>
                <Heading variant='h2' sx={styles.h2Black}>{props.data.header}</Heading>
                <Text variant='p' sx={styles.pBlack}>
                  {props.data.content && renderRichText(props.data.content, options)}
                </Text>
              </Box>
              <Box p={1} sx={styles.box2}>
                <Box style={{position:`absolute`, top:`6px`, right:`6px`}}>
                  <Link href="#" onClick={closeModal} sx={styles.link}><FaTimesCircle style={{fontSize:`2em`, color:`white`, margingTop:`5px`, padding:0}}/></Link>
                </Box>
                <Heading variant='h2' sx={styles.h2}>{props.data.newsletterBox.headerTitle}</Heading>
                <Text variant='p' sx={styles.h2}>
                  {props.data.newsletterBox.description}
                </Text>
                <Box sx={styles.form}>
                  <NewsletterForm
                    {...{
                      handleSubmit,
                      canSubmit,
                      submitting,
                      message,
                      success
                    }}
                    buttonTitle={props.data.newsletterBox.buttonTitle}
                  />
                </Box>
              </Box>
            </Flex>
          **/}
            <Box p={1} bg='black' sx={styles.header}>
              <Heading variant='h2' sx={styles.h2}>{props.data.newsletterBox.headerTitle}</Heading>
              <Text variant='p'>
                {props.data.newsletterBox.description}
              </Text>
            </Box>
            <Flex>
              <Box p={1} sx={styles.box2}>
                <img src={props.data.newsletterBox.picture.fluid.src} style={{width:`85%`}} alt="inscription newsletter"/>
              </Box>
              <Box p={1} sx={styles.box1}>
                <Box style={{position:`absolute`, top:`6px`, right:`6px`}}>
                  <span style={{cursor:`pointer`}} onClick={closeModal} sx={styles.link}><FaTimesCircle style={{fontSize:`2em`, color:`white`, margingTop:`5px`, padding:0}}/></span>
                </Box>
                <Box sx={styles.form}>
                  <NewsletterForm
                    {...{
                      handleSubmit,
                      canSubmit,
                      submitting,
                      message,
                      success
                    }}
                    buttonTitle={props.data.newsletterBox.buttonTitle}
                  />
                </Box>
              </Box>
            </Flex>
        </Card>
      </Main>
    </Modal>
	)
}

export default ExitIntentModal